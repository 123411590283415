import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
} from "@chakra-ui/react";
import { Key } from "react";
import { NavLink } from "react-router-dom";

function TabsContact(props: any) {
  const { data } = props;

  return (
    <div className="contact-container">
      <Tabs isFitted variant="line" colorScheme="blackAlpha" w={"100vw"}>
        <TabList>
          {data.map(
            (
              tab: { id: string; icon: string; alt: string; name: string },
              index: Key
            ) => (
              <Tab
                key={index}
                id={tab.id}
                className="contact-tabs"
                sx={{
                  fontFamily: "Rajdhani",
                  fontSize: "1.3rem",
                  fontWeight: "600",
                  display: "flex",
                  gap: "2rem",
                  marginTop: "1rem",
                }}
              >
                <img src={tab.icon} alt={tab.alt} />
                {tab.name}
              </Tab>
            )
          )}
        </TabList>
        <TabPanels className="panels-contact">
          {data.map(
            (
              tab: {
                id: string;
                text: string;
                emailIcon: string;
                infoText: string;
                link: string;
              },
              index: Key
            ) => (
              <TabPanel p={4} key={index} id={tab.id} w={"90dvw"}>
                <div className="contact-tab-content">
                  <p>{tab.text}</p>
                  <Divider />
                  <div className="contact-tab-content-button">
                    <div className="content-icon">{tab.emailIcon}</div>
                    <button key={index}>
                      <NavLink to={tab.link}>{tab.infoText}</NavLink>
                    </button>
                  </div>
                </div>
              </TabPanel>
            )
          )}
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default TabsContact;
