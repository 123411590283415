import Cloud from "../Assets/Icon - Cloud.svg";

function ProductCloud() {
  return (
    <div>
      <img src={Cloud} alt="Products" />
    </div>
  );
}

export default ProductCloud;
