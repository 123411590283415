import { ProductsContent } from "../Data";
import Cards from "./Cards";

function Products() {
  return (
    <div>
      <Cards data={ProductsContent} />
    </div>
  );
}

export default Products;
