import { NavbarLinks } from "../../Data";
import NavbarProps from "./NavbarProps";
import Logo from "../../Assets/AllCloudVideo-Logo-Transparent.svg";
import MobileNav from "./MobileNav";
import SocialMedia from "./SocialMedia";

function Navbar() {
  return (
    <header className="navbar-container">
      <img src={Logo} alt="All cloud video Logo" className="logo-full" />
      <div className="nav-links-container">
        {NavbarLinks.map(({ id, name, link }, index) => {
          return (
            <div key={index}>
              <NavbarProps id={id} link={link} name={name} />
            </div>
          );
        })}
      </div>
      <div className="social-media-full-nav">
        <SocialMedia />
      </div>
      <MobileNav />
    </header>
  );
}

export default Navbar;
