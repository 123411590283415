import { Key } from "react";
import { NavLink } from "react-router-dom";

function Button(props: any) {
  const { data } = props;

  return (
    <div className="contact-tab-content-button ">
      {data.map(
        (
          item: { icon: string; link: string; infoText: string },
          index: Key
        ) => {
          return (
            <div key={index} className="btn">
              <div >{item.icon}</div>
              <button
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <NavLink to={item.link}>{item.infoText}</NavLink>
              </button>
            </div>
          );
        }
      )}
    </div>
  );
}

export default Button;
