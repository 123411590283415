import PageUnderConstruction from '../PageUnderConstruction'

function TrainingCourses() {
  return (
    <div>
        <PageUnderConstruction />
    </div>
  )
}

export default TrainingCourses