import MobileMenu from "./MobileMenu";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import Logo from "../../Assets/AllCloudVideo-Logo-Transparent.svg";
import CloseMobileMenu from "../../Icons/CloseMobileMenu";
import OpenMobileMenu from "../../Icons/OpenMobileMenu";

function MobileNav() {
  return (
    <header className="mobile-nav-container">
      <img src={Logo} alt="logo" />
      <Menu gutter={19} direction={"ltr"}>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              bg="transparent"
              _hover={{ bg: "transparent" }}
              colorScheme="white"
              sx={{
                inset: "0",
              }}
            >
              {isOpen ? <CloseMobileMenu /> : <OpenMobileMenu />}
            </MenuButton>
            <MenuList p="0" borderWidth="0" marginTop="5" boxShadow={"0px 0px 8px #ccc"}>
              <MenuItem closeOnSelect={true} paddingInlineStart="0" p="0">
                <MobileMenu />
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </header>
  );
}

export default MobileNav;
