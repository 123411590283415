import { AccordionData } from "../Data";
import { useState } from "react";

function AccordionToggle() {
  const [isVisible, setVisible] = useState(false);

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <div className="accordion-container">
      {AccordionData.map(({ id, icon, text, link }) => {
        return (
          <div
            key={id}
            className="accordion-parent"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="accordion-icon">
              <button>{icon}</button>
            </div>
            <div className="accordion-text">
              {isVisible && (
                <button>
                    <a href={link}>
                      <h2>{text}</h2>
                    </a>
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AccordionToggle;
