import NavbarProps from "./NavbarProps";
import { NavbarLinks } from "../../Data";
import SocialMedia from "./SocialMedia";

function MobileMenu() {
  return (
    <div>
      <nav className="mobile-nav-menu">
        {NavbarLinks.map(({ id, name, link }, index) => {
          return (
            <div className="mobile-nav" key={index}>
              <NavbarProps id={id} name={name} link={link} />
            </div>
          );
        })}
      </nav>

      <hr />
      <SocialMedia />
    </div>
  );
}

export default MobileMenu;
