import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Products from "./Components/Products";
import Solutions from "./Components/Solutions";
import TutorialsAndTraining from "./Components/TutorialsAndTraining/TutorialsAndTraining";
import Contact from "./Components/Contact";
import Navbar from "./Components/Navbar/Navbar";
import AccordionToggle from "./Components/Accordion";
import Footer from "./Components/Footer/Footer";
import { Content } from "./Data";
import FooterAccordion from "./Components/Footer/FooterAccordion";
import VideoTutorial from "./Components/TutorialsAndTraining/VideoTutorial";
import TrainingCourses from "./Components/TutorialsAndTraining/TrainingCourses";
import ReturnButton from "./Components/ReturnButton";
import AboutUs from "./Components/AboutUs";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <ReturnButton />
      <AccordionToggle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route
          path="/tutorialsandtraining"
          element={<TutorialsAndTraining />}
        />
        <Route
          path="/tutorialsandtraining/videotutorials"
          element={<VideoTutorial />}
        />
        <Route
          path="/tutorialsandtraining/trainingcourses"
          element={<TrainingCourses />}
        />
      </Routes>
      <Footer data={Content} />
      <FooterAccordion data={Content} />
    </BrowserRouter>
  );
}

export default App;
