import React from "react";
import { NavLink } from "react-router-dom";
import SocialMedia from "../Navbar/SocialMedia";

function FooterCopyright() {
  const getCurrenYear = () => {
    return new Date().getFullYear();
  };

  return (
    <section className="footer-copyright-container">
      <div className="footer-copyright">
        <small>&copy; Copyright {getCurrenYear()} - All Cloud Video</small>
        <small>
          <NavLink to={"https://www.francinemelopepe.de/"}>
            Developed by -- Francine Pêpe --
          </NavLink>
        </small>
      </div>
      <div>
        <SocialMedia />
      </div>
    </section>
  );
}

export default FooterCopyright;
