import Cards from './Cards'
import { SolutionsContent } from '../Data'

function Solutions() {
  return (
    <div>
      <Cards data={SolutionsContent} />
    </div>
  )
}

export default Solutions