import EmailIcon from "./Icons/EmailIcon";
import PhoneIcon from "./Icons/PhoneIcon";
import CloudVideo from "./Assets/Cloud-video.svg";
import CloudAroundWorld from "./Assets/Cloud-around-world.svg";
import CloudWhyUse from "./Assets/Cloud-why-use.svg";
import Training from "./Assets/Training.png";
import Time from "./Assets/Time.png";
import DataSync from "./Assets/DataSync.png";
import LiveStreaming from "./Assets/LiveStreaming.svg";
import VideoDemand from "./Assets/VideoOnDemand.svg";
import PackagingDelivering from "./Assets/PackagingDelivering.svg";
import ServerSide from "./Assets/ServerSide.svg";
import Storage from "./Assets/Storage.svg";
import AwsCloud from "./Assets/AwsCloud.svg";
import ConsultingTraining from "./Assets/ConsultingTraining.svg";
import TrainingCourses from "./Assets/TrainingCourses.png";
import VideoTutorial from "./Assets/VideoTutorial.png";
import ContactSupport from "./Assets/Icon - Contact Support.png";
import ContactSale from "./Assets/Icon - Contact Sale.png";
import EmailBlack from "./Icons/EmailIconBlack";
import Cloud from "./Icons/ProductCloud";

export const NavbarLinks = [
  {
    id: 0,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Products",
    link: "/products",
  },
  {
    id: 2,
    name: "Solutions",
    link: "/solutions",
  },
  {
    id: 3,
    name: "Tutorials and Training",
    link: "/tutorialsandtraining",
  },
  {
    id: 4,
    name: "Contact",
    link: "/contact",
  },
];

export const AccordionData = [
  {
    id: 0,
    icon: <PhoneIcon />,
    text: "+1 (469) 428-4849",
    link: "tel:+14694284849",
  },
  {
    id: 1,
    icon: <EmailIcon />,
    text: "contact@allcloudvideo.com",
    link: "mailto:contact@allcloudvideo.com",
  },
];

export const TabsContent = [
  {
    id: "1",
    name: "What is Video on Cloud",
    image: CloudVideo,
    alt: "What is Video on Cloud",
    text: "Video on cloud refers to the storage, processing, and delivery of video content using cloud computing infrastructure. Instead of storing videos locally on physical devices like hard drives or servers, they are uploaded to remote servers hosted by cloud service providers such as Amazon Web Services (AWS). Overall, video on cloud solutions streamline the management and distribution of video content, making it easier for businesses, content creators, and consumers to leverage the benefits of cloud computing for their video-related needs.",
  },
  {
    id: "2",
    name: "Information of Streaming and video in the world",
    image: CloudAroundWorld,
    alt: "Information of Streaming and video in the world",
    text: "Streaming and video consumption have become integral parts of modern entertainment and communication worldwide. Overall, streaming and video consumption continue to evolve rapidly, driven by technological advancements, changing consumer preferences, and the competitive dynamics of the market.",
  },
  {
    id: "3",
    name: "Reasons to use video in the cloud",
    image: CloudWhyUse,
    alt: "Reasons to use video in the cloud",
    text: "Overall, leveraging video in the cloud offers numerous benefits, including scalability, cost-effectiveness, accessibility, security, flexibility, reliability, and analytics. These advantages make it an attractive option for businesses looking to harness the power of video for communication, collaboration, marketing, training, and more.",
  },
];

export const Content = [
  {
    heading: "About All Cloud Video",
    links: [
      {
        name: "About Us",
        link: "/aboutUs",
        id: "aboutUs",
      },
    ],
  },
  {
    heading: "Products",
    links: [
      {
        name: "Live Streaming Support Services",
        link: "/products",
        id: 1,
      },
      {
        name: "Video On-Demand (VOD) Support Solutions",
        link: "/products",
        id: 2,
      },
      {
        name: "Packaging and Delivering Live and VOD Content",
        link: "/products",
        id: 3,
      },
      {
        name: "Server Side Ad Insertion Workflows",
        link: "/products",
        id: 4,
      },
      {
        name: "Storage as a Service",
        link: "/products",
        id: 5,
      },
      {
        name: "AWS Cloud Support - IaaS - PaaS - SaaS",
        link: "/products",
        id: 6,
      },
      {
        name: "Consulting and Training",
        link: "/products",
        id: 7,
        products: [
          {
            id: 7.1,
            product: "Consulting and Training:",
            subProduct: [
              {
                id: 7.11,
                product:
                  "Tailored consultancy services to assess and optimize streaming infrastructure",
              },
              {
                id: 7.12,
                product:
                  "Customized training programs to empower teams with in-depth knowledge of streaming technologies",
              },
              {
                id: 7.13,
                product:
                  "Strategic guidance on scaling and expanding streaming services to meet growing demands",
              },
            ],
          },
          {
            id: 7.2,
            product: "24/7 Technical Support: ",
            subProduct: [
              {
                id: 7.21,
                product:
                  "Round-the-clock technical support for critical issues impacting streaming services",
              },
              {
                id: 7.22,
                product:
                  "Dedicated support team available to address emergencies and minimize downtime",
              },
              {
                id: 7.23,
                product:
                  "Proactive monitoring and maintenance to ensure continuous uptime and optimal performance",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    heading: "Solutions",
    links: [
      {
        name: "Live Streaming",
        link: "/solutions",
        id: "solution01",
      },
      {
        name: "Video On-Demand",
        link: "/solutions",
        id: "solution02",
      },
      {
        name: "VOD Content",
        link: "/solutions",
        id: "solution03",
      },
      {
        name: "Server Side",
        link: "/solutions",
        id: "solution04",
      },
      {
        name: "Storage",
        link: "/solutions",
        id: "solution05",
      },
      {
        name: "AWS Cloud Support",
        link: "/solutions",
        id: "solution06",
      },
      {
        name: "Consulting and Training",
        link: "/solutions",
        id: "solution07",
      },
    ],
  },
  {
    heading: "Tutotials and Training",
    links: [
      {
        name: "Free Tutorials",
        link: "/tutorialsandtraining",
        id: "tutorial01",
      },
      {
        name: "Online Training",
        link: "/tutorialsandtraining",
        id: "tutorial02",
      },
    ],
  },
  {
    heading: "Contact",
    links: [
      {
        name: "Online meeting",
        link: "/contact",
        id: "contact01",
      },
      {
        name: "Email",
        link: "/contact",
        id: "contact02",
      },
    ],
  },
];

export const searchData = [
  {
    name: "Live Streaming Support Services",
    link: "/products",
    id: "products01",
  },
  {
    name: "Video On-Demand (VOD) Support Solutions",
    link: "/products",
    id: "products02",
  },
  {
    name: "Packaging and Delivering Live and VOD Content",
    link: "/products",
    id: "products03",
  },
  {
    name: "Server Side Ad Insertion Workflows",
    link: "/products",
    id: "products04",
  },
  {
    name: "Storage as a Service",
    link: "/products",
    id: "products05",
  },
  {
    name: "AWS Cloud Support - IaaS - PaaS - SaaS",
    link: "/products",
    id: "products06",
  },
  {
    name: "Consulting and Training",
    link: "/products",
    id: "products07",
  },
  {
    name: "Live Streaming",
    link: "/solutions",
    id: "solution01",
  },
  {
    name: "Video On-Demand",
    link: "/solutions",
    id: "solution02",
  },
  {
    name: "VOD Content",
    link: "/solutions",
    id: "solution03",
  },
  {
    name: "Server Side",
    link: "/solutions",
    id: "solution04",
  },
  {
    name: "Storage",
    link: "/solutions",
    id: "solution05",
  },
  {
    name: "AWS Cloud Support",
    link: "/solutions",
    id: "solution06",
  },
  {
    name: "Consulting and Training",
    link: "/solutions",
    id: "solution07",
  },
  {
    name: "Free Tutorials",
    link: "/tutorialsandtraining/freeTutorials",
    id: "tutorial01",
  },
  {
    name: "Online Training",
    link: "/tutorialsandtraining/onlineTraining",
    id: "tutorial02",
  },
];

export const ProductsContent = [
  {
    name: " Live Streaming",
    link: "/products",
    id: 1,
    icon: LiveStreaming,
    products: [
      {
        id: 1.1,
        product: "Configuration and optimization of live streaming setups",
      },
      {
        id: 1.2,
        product:
          "Troubleshooting and resolution of issues during live broadcasts",
      },
      {
        id: 1.3,
        product:
          "Assistance with setup and management of live events, ensuring smooth streaming experiences",
      },
      {
        id: 1.4,
        product:
          "Configuration and optimization of AWS Live streaming tools for live video encoding and streaming",
      },
      {
        id: 1.5,
        product:
          "Troubleshooting and resolution of issues with MediaLive channels and workflows",
      },
      {
        id: 1.6,
        product:
          "Assistance with setup and management of MediaLive channels for various streaming applications",
      },
    ],
  },
  {
    name: "Video On-Demand ",
    link: "/products",
    id: 2,
    icon: VideoDemand,
    products: [
      {
        id: 2.1,
        product:
          "Optimization of video on-demand workflows for efficient content delivery",
      },
      {
        id: 2.2,
        product:
          "Troubleshooting and resolution of issues related to VOD content processing and delivery",
      },
      {
        id: 2.3,
        product:
          "Assistance with VOD platform setup, customization, and management",
      },
      {
        id: 2.4,
        product:
          "Configuration and optimization of AWS File-Based Services for video encoding and transcoding",
      },
      {
        id: 2.5,
        product: " Troubleshooting and resolution of issues with VOD workflows",
      },
      {
        id: 2.6,
        product:
          "Guidance on best practices for utilizing VOD to achieve desired video processing outcomes",
      },
    ],
  },
  {
    name: "Packaging and Delivering Live and VOD Content",
    link: "/products",
    id: 3,
    icon: PackagingDelivering,
    products: [
      {
        id: 3.1,
        product: "AWS Packaging services:",
        subProduct: [
          {
            id: 3.11,
            product:
              "Configuration and optimization of AWS Packaging services for secure video packaging and delivery",
          },
          {
            id: 3.12,
            product:
              "Troubleshooting and resolution of issues related to MediaPackage configuration and delivery",
          },
          {
            id: 3.13,
            product:
              "Guidance on implementing DRM and content protection features with AWS Packaging Services",
          },
        ],
      },
      {
        id: 3.2,
        product: "Content Delivery Network (CDN):",
        subProduct: [
          {
            id: 3.21,
            product:
              "Optimization and configuration of CDN solutions for efficient content delivery",
          },
          {
            id: 3.22,
            product:
              "Troubleshooting and resolution of issues with CDN setup, caching, and distribution",
          },
          {
            id: 3.23,
            product:
              " Assistance with CDN selection, setup, and integration with streaming platforms",
          },
        ],
      },
    ],
  },
  {
    name: "Server Side Ad",
    link: "/products",
    id: 4,
    icon: ServerSide,
    products: [
      {
        id: 4.1,
        product: "Server-side ad:",
        subProduct: [
          {
            id: 4.11,
            product:
              "Troubleshooting and resolution of issues related to Ad Insertion content processing and delivery",
          },
          {
            id: 4.12,
            product:
              "Assistance with Ad Insertion platform setup, customization, and management",
          },
          {
            id: 4.13,
            product:
              "Configuration and optimization of Ad Insertion Services for audio and video",
          },
          {
            id: 4.14,
            product:
              "Troubleshooting and resolution of issues with Ad Insertion workflows",
          },
          {
            id: 4.15,
            product:
              "Guidance on best practices for utilizing Ad server insertion to achieve desired",
          },
          {
            id: 4.16,
            product: "Advertising processing outcomes",
          },
        ],
      },
      {
        id: 4.2,
        product: "FAST Channels:",
        subProduct: [
          {
            id: 4.21,
            product:
              "Troubleshooting and resolution of issues related to FAST channels content processing and delivery.",
          },
          {
            id: 4.22,
            product:
              "Assistance with FAST channels platform setup, customization, and management",
          },
          {
            id: 4.23,
            product:
              "Configuration and optimization of FAST channels Services for audio and video",
          },
          {
            id: 4.24,
            product:
              "Troubleshooting and resolution of issues with FAST channels workflows",
          },
          {
            id: 4.25,
            product:
              "Guidance on best practices for utilizing FAST channels insertion to achieve desired Advertising processing outcomes",
          },
        ],
      },
    ],
  },
  {
    name: "Storage",
    link: "/products",
    id: 5,
    icon: Storage,
    products: [
      {
        id: 5.1,
        product: "AWS S3 - SFTP - Datasync - Storage Gateway Support - Backup:",
        icon: DataSync,
        subProduct: [
          {
            id: 5.11,
            product:
              "Optimization and configuration of Storage solutions for efficient store and backup Audio/Video Content",
          },
          {
            id: 5.12,
            product:
              "Troubleshooting and resolution of issues with Storage solutions for efficient store and backup Audio/Video Content setup, store, and backup",
          },
          {
            id: 5.13,
            product:
              " Assistance with Storage model selection, setup, and integration with streaming platforms",
          },
        ],
      },
    ],
  },
  {
    name: "AWS Cloud",
    link: "/products",
    id: 6,
    icon: AwsCloud,
    products: [
      {
        id: 6.1,
        product:
          "Optimization of Cloud workflows for efficient lifecycle of data in the Cloud",
      },
      {
        id: 6.2,
        product:
          " Troubleshooting and resolution of issues related to AWS Cloud infraestructure and management",
      },
      {
        id: 6.3,
        product:
          "Assistance with AWS Cloud platform setup, customization, and management",
      },
      {
        id: 6.4,
        product:
          "Configuration and optimization of AWS Cloud Services for different AWS Services",
      },
      {
        id: 6.5,
        product:
          "Troubleshooting and resolution of issues with AWS Cloud IaaS - PaaS - SaaS -",
      },
      {
        id: 6.6,
        product:
          "Guidance on best practices for utilizing IaaS - PaaS - SaaS to achieve desired Hosting in the Cloud",
      },
    ],
  },
  {
    name: "Consulting and Training",
    link: "/products",
    id: 7,
    icon: ConsultingTraining,
    products: [
      {
        id: 7.1,
        product: "Consulting and Training:",
        icon: Training,
        subProduct: [
          {
            id: 7.11,
            product:
              "Tailored consultancy services to assess and optimize streaming infrastructure",
          },
          {
            id: 7.12,
            product:
              "Customized training programs to empower teams with in-depth knowledge of streaming technologies",
          },
          {
            id: 7.13,
            product:
              "Strategic guidance on scaling and expanding streaming services to meet growing demands",
          },
        ],
      },
      {
        id: 7.2,
        product: "24/7 Technical Support: ",
        icon: Time,
        subProduct: [
          {
            id: 7.21,
            product:
              "Round-the-clock technical support for critical issues impacting streaming services",
          },
          {
            id: 7.22,
            product:
              "Dedicated support team available to address emergencies and minimize downtime",
          },
          {
            id: 7.23,
            product:
              "Proactive monitoring and maintenance to ensure continuous uptime and optimal performance",
          },
        ],
      },
    ],
  },
];

export const SolutionsContent = [
  {
    name: " Live Streaming",
    link: "/solutions",
    id: 1,
    icon: LiveStreaming,
    products: [
      {
        id: 1.1,
        product: "Configuration and optimization of live streaming setups",
      },
      {
        id: 1.2,
        product:
          "Troubleshooting and resolution of issues during live broadcasts",
      },
      {
        id: 1.3,
        product:
          "Assistance with setup and management of live events, ensuring smooth streaming experiences",
      },
      {
        id: 1.4,
        product:
          "Configuration and optimization of AWS Live streaming tools for live video encoding and streaming",
      },
      {
        id: 1.5,
        product:
          "Troubleshooting and resolution of issues with MediaLive channels and workflows",
      },
      {
        id: 1.6,
        product:
          "Assistance with setup and management of MediaLive channels for various streaming applications",
      },
    ],
  },
  {
    name: "Video On-Demand ",
    link: "/solutions",
    id: 2,
    icon: VideoDemand,
    products: [
      {
        id: 2.1,
        product:
          "Optimization of video on-demand workflows for efficient content delivery",
      },
      {
        id: 2.2,
        product:
          "Troubleshooting and resolution of issues related to VOD content processing and delivery",
      },
      {
        id: 2.3,
        product:
          "Assistance with VOD platform setup, customization, and management",
      },
      {
        id: 2.4,
        product:
          "Configuration and optimization of AWS File-Based Services for video encoding and transcoding",
      },
      {
        id: 2.5,
        product: " Troubleshooting and resolution of issues with VOD workflows",
      },
      {
        id: 2.6,
        product:
          "Guidance on best practices for utilizing VOD to achieve desired video processing outcomes",
      },
    ],
  },
  {
    name: "Packaging and Delivering Live and VOD Content",
    link: "/solutions",
    id: 3,
    icon: PackagingDelivering,
    products: [
      {
        id: 3.1,
        product: "AWS Packaging services:",
        subProduct: [
          {
            id: 3.11,
            product:
              "Configuration and optimization of AWS Packaging services for secure video packaging and delivery",
          },
          {
            id: 3.12,
            product:
              "Troubleshooting and resolution of issues related to MediaPackage configuration and delivery",
          },
          {
            id: 3.13,
            product:
              "Guidance on implementing DRM and content protection features with AWS Packaging Services",
          },
        ],
      },
      {
        id: 3.2,
        product: "Content Delivery Network (CDN):",
        subProduct: [
          {
            id: 3.21,
            product:
              "Optimization and configuration of CDN solutions for efficient content delivery",
          },
          {
            id: 3.22,
            product:
              "Troubleshooting and resolution of issues with CDN setup, caching, and distribution",
          },
          {
            id: 3.23,
            product:
              " Assistance with CDN selection, setup, and integration with streaming platforms",
          },
        ],
      },
    ],
  },
  {
    name: "Server Side Ad",
    link: "/solutions",
    id: 4,
    icon: ServerSide,
    products: [
      {
        id: 4.1,
        product: "Server-side ad:",
        subProduct: [
          {
            id: 4.11,
            product:
              "Troubleshooting and resolution of issues related to Ad Insertion content processing and delivery",
          },
          {
            id: 4.12,
            product:
              "Assistance with Ad Insertion platform setup, customization, and management",
          },
          {
            id: 4.13,
            product:
              "Configuration and optimization of Ad Insertion Services for audio and video",
          },
          {
            id: 4.14,
            product:
              "Troubleshooting and resolution of issues with Ad Insertion workflows",
          },
          {
            id: 4.15,
            product:
              "Guidance on best practices for utilizing Ad server insertion to achieve desired",
          },
          {
            id: 4.16,
            product: "Advertising processing outcomes",
          },
        ],
      },
      {
        id: 4.2,
        product: "FAST Channels:",
        subProduct: [
          {
            id: 4.21,
            product:
              "Troubleshooting and resolution of issues related to FAST channels content processing and delivery.",
          },
          {
            id: 4.22,
            product:
              "Assistance with FAST channels platform setup, customization, and management",
          },
          {
            id: 4.23,
            product:
              "Configuration and optimization of FAST channels Services for audio and video",
          },
          {
            id: 4.24,
            product:
              "Troubleshooting and resolution of issues with FAST channels workflows",
          },
          {
            id: 4.25,
            product:
              "Guidance on best practices for utilizing FAST channels insertion to achieve desired Advertising processing outcomes",
          },
        ],
      },
    ],
  },
  {
    name: "Storage",
    link: "/solutions",
    id: 5,
    icon: Storage,
    products: [
      {
        id: 5.1,
        product: "AWS S3 - SFTP - Datasync - Storage Gateway Support - Backup:",
        icon: DataSync,
        subProduct: [
          {
            id: 5.11,
            product:
              "Optimization and configuration of Storage solutions for efficient store and backup Audio/Video Content",
          },
          {
            id: 5.12,
            product:
              "Troubleshooting and resolution of issues with Storage solutions for efficient store and backup Audio/Video Content setup, store, and backup",
          },
          {
            id: 5.13,
            product:
              " Assistance with Storage model selection, setup, and integration with streaming platforms",
          },
        ],
      },
    ],
  },
  {
    name: "AWS Cloud",
    link: "/solutions",
    id: 6,
    icon: AwsCloud,
    products: [
      {
        id: 6.1,
        product:
          "Optimization of Cloud workflows for efficient lifecycle of data in the Cloud",
      },
      {
        id: 6.2,
        product:
          " Troubleshooting and resolution of issues related to AWS Cloud infraestructure and management",
      },
      {
        id: 6.3,
        product:
          "Assistance with AWS Cloud platform setup, customization, and management",
      },
      {
        id: 6.4,
        product:
          "Configuration and optimization of AWS Cloud Services for different AWS Services",
      },
      {
        id: 6.5,
        product:
          "Troubleshooting and resolution of issues with AWS Cloud IaaS - PaaS - SaaS -",
      },
      {
        id: 6.6,
        product:
          "Guidance on best practices for utilizing IaaS - PaaS - SaaS to achieve desired Hosting in the Cloud",
      },
    ],
  },
  {
    name: "Consulting and Training",
    link: "/solutions",
    id: 7,
    icon: ConsultingTraining,
    products: [
      {
        id: 7.1,
        product: "Consulting and Training:",
        icon: Training,
        subProduct: [
          {
            id: 7.11,
            product:
              "Tailored consultancy services to assess and optimize streaming infrastructure",
          },
          {
            id: 7.12,
            product:
              "Customized training programs to empower teams with in-depth knowledge of streaming technologies",
          },
          {
            id: 7.13,
            product:
              "Strategic guidance on scaling and expanding streaming services to meet growing demands",
          },
        ],
      },
      {
        id: 7.2,
        product: "24/7 Technical Support: ",
        icon: Time,
        subProduct: [
          {
            id: 7.21,
            product:
              "Round-the-clock technical support for critical issues impacting streaming services",
          },
          {
            id: 7.22,
            product:
              "Dedicated support team available to address emergencies and minimize downtime",
          },
          {
            id: 7.23,
            product:
              "Proactive monitoring and maintenance to ensure continuous uptime and optimal performance",
          },
        ],
      },
    ],
  },
];

export const TrainingAndSolutions = [
  {
    id: 1,
    icon: VideoDemand,
    name: "Video Tutorial",
    link: "/tutorialsandtraining/videotutorials",
    description:
      "Are you looking to learn new skills, master our products, or enhance your knowledge? Our video tutorials are here to guide you every step of the way. Whether you're a beginner or an experienced user, our comprehensive video library covers a wide range of topics to help you succeed. Explore our video tutorials and start your learning journey today! Whether you're looking to sharpen your skills, troubleshoot issues, or discover new possibilities, our video tutorials are here to empower you every step of the way.",
    image: VideoTutorial,
  },
  {
    id: 2,
    icon: ConsultingTraining,
    name: "Training Courses",
    link: "/tutorialsandtraining/trainingcourses",
    description:
      "Are you ready to take your skills to the next level? Our training courses offer comprehensive learning experiences designed to empower you with the knowledge, tools, and techniques you need to succeed. Whether you're a beginner looking to build a solid foundation or an experienced professional seeking advanced expertise, our diverse range of courses has something for everyone. Browse our catalog of training courses and find the perfect program to kickstart your learning journey.",
    image: TrainingCourses,
  },
];

export const ContactData = [
  {
    id: 1,
    icon: ContactSupport,
    alt: "Support icon",
    name: "Contact Support",
    text: "Need assistance or have a question? Our dedicated support team is here to help. Please feel free to reach out to us using any of the methods below. Our goal is to ensure that you have the best possible experience with our products and services. Thank you for choosing All Cloud Videos!",
    emailIcon: <EmailBlack />,
    infoText: "contact@allcloudvideo.com",
    link: "mailto:contact@allcloudvideo.com",
  },
  {
    id: 2,
    icon: ContactSale,
    alt: "Sale icon",
    name: "Contact Sale",
    text: "Interested in learning more about our products or services? Our knowledgeable sales team is here to assist you. Please don't hesitate to reach out using any of the methods below. Our goal is to provide you with the information and support you need to make informed decisions for your business.Thank you for considering All Cloud Videos as your trusted partner!",
    emailIcon: <EmailBlack />,
    infoText: "sales@allcloudvideo.com",
    link: "mailto:sales@allcloudvideo.com",
  },
];

export const HomeButton = [
  {
    icon: <Cloud />,
    infoText: "To our Products",
    link: "/products",
  },
];
