import { NavLink } from "react-router-dom";
import Email from "../Assets/Icon - E-mail.svg";

function EmailIcon() {
  return (
    <div>
      <NavLink to="/">
        <img src={Email} alt="Email" />
      </NavLink>
    </div>
  );
}

export default EmailIcon;
