import { useLocation } from "react-router-dom";
import { Key } from "react";

function Cards(props: any) {
  const { data } = props;
  const location = useLocation();

  const title = () => {
    if (location.pathname === "/products") {
      return <h1>Products</h1>;
    }
    if (location.pathname === "/solutions") {
      return <h1>Solutions</h1>;
    }
  };

  return (
    <main className="card-parent">
      <h1>{title()}</h1>
      <div className="tabs-container">
        {data.map(
          (
            tab: {
              title: string;
              products: any;
              name: string;
              id: string;
              icon: string;
            },
            index: Key
          ) => {
            return (
              <div key={index} id={tab.id} className="cards">
                <div className="tab-content-text-title">
                  <div className="tab-content-img">
                    <img
                      src={tab.icon}
                      alt=""
                      style={{
                        display: tab.icon ? "block" : "none",
                      }}
                    />
                  </div>
                  <h1>{tab.name} </h1>
                </div>

                {tab.products?.map((product: any, i: Key) => {
                  return (
                    <div key={i} className="tab-panel">
                      <div className="tab-content-text ">
                        <ul>
                          <li>{product.product}</li>
                        </ul>
                        <div
                          className="tab-content-subProduct"
                          style={{
                            display: product.subProduct ? "block" : "none",
                          }}
                        >
                          {product.subProduct?.map((product: any, id: Key) => (
                            <div key={id} className="tab-content-text subProduct">
                              <ul id={product.id}>
                                <li>{product.product}</li>
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }
        )}
      </div>
    </main>
  );
}

export default Cards;
