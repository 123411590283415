import React from "react";
import Linkedin from "../../Icons/Linkedin";
import Instagram from "../../Icons/Instagram";
import Facebook from "../../Icons/Facebook";

function SocialMedia() {
  return (
    <section className="social-media-container">
      <Linkedin />
      <Instagram />
      <Facebook />
    </section>
  );
}

export default SocialMedia;
