import { NavLink } from "react-router-dom";
import { Key } from "react";
import { Divider } from "@chakra-ui/react";
import FooterCopyright from "./FooterCopyright";

function Footer(props: any) {
  const { data } = props;

  return (
    <>
      <footer className="footer-container footer-desktop">
        {data.map(
          (
            item: {
              heading: string;
              links: any[];
            },
            index: Key
          ) => {
            return (
              <div className="footer-content" key={index}>
                <h1>{item.heading}</h1>
                <div className="footer-heading-links">
                  {item.links?.map((link, i) => (
                    <div key={i} id={link.id}>
                      <ul className="menu-list footer-list">
                        <li className="current">
                          <NavLink
                            to={link.link}
                            data-hover={link.name}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            {link.name}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            );
          }
        )}
        <Divider colorScheme="blackAlpha" />
        <FooterCopyright />
      </footer>
    </>
  );
}

export default Footer;
