import { NavLink } from "react-router-dom";
import LinkedinIcon from "../Assets/Icon - Linkedin.svg";

function Linkedin() {
  return (
    <div className="social-media-icon">
      <NavLink to={"/"}>
        <img src={LinkedinIcon} alt="" />
      </NavLink>
    </div>
  );
}

export default Linkedin;
