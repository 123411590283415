import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { Key } from "react";
import { NavLink, To } from "react-router-dom";
import FooterCopyright from "./FooterCopyright";
function FooterAccordion(props: any) {
  const { data } = props;

  return (
    <footer className="footer-container footer-mobile">
      <Accordion allowToggle w="100%">
        {data.map(
          (
            item: {
              links: any;
              heading: string;
            },
            index: any
          ) => {
            return (
              <AccordionItem className="footer-content" key={index}>
                <h1>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize="1.2rem"
                      className="footer-mobile-box"
                    >
                      {item.heading}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h1>
                <div className="footer-heading-links footer-mobile-links">
                  {item.links?.map(
                    (
                      link: {
                        id: string | undefined;
                        link: To;
                        name: string;
                      },
                      i: Key
                    ) => (
                      <AccordionPanel pb={4} key={i}>
                        <div id={link.id}>
                          <ul className="footer-list footer-mobile-list">
                            <li>
                              <NavLink to={link.link} data-hover={link.name}>
                                {link.name}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </AccordionPanel>
                    )
                  )}
                </div>
              </AccordionItem>
            );
          }
        )}
      </Accordion>
      <FooterCopyright />
    </footer>
  );
}

export default FooterAccordion;
