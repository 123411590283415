import { NavLink } from "react-router-dom";
import { TrainingAndSolutions } from "../../Data";

function TutorialsAndTraining() {
  return (
      <main className="tutorials-training-container">
        {TrainingAndSolutions.map(
          (
            data: {
              id: any;
              icon: any;
              name: string;
              link: string;
              description: string;
              image: string;
            },
            index
          ) => {
            return (
              <div key={index}>
                <NavLink to={data.link}>
                  <div className="tutorials-training-content" id={data.id}>
                    <div className="tutorials-training-content-title">
                      <img src={data.icon} alt="" />
                      <h1>{data.name}</h1>
                    </div>
                    <p>{data.description}</p>
                    <div className="tutorial-training-content-image">
                      <img src={data.image} alt="" />
                    </div>
                  </div>
                </NavLink>
              </div>
            );
          }
        )}
      </main>
  );
}

export default TutorialsAndTraining;
