import { useNavigate, useLocation } from "react-router-dom";
import ReturnArrow from "../Assets/Icon - Arrow_back.svg";

function ReturnButton() {
  let navigate = useNavigate();
  const location = useLocation();

  const hideButton = location.pathname === "/";

  function pageNavigate() {
    navigate(-1);
  }
  function windowScroll() {
    window.scroll(0, 0);
  }

  return (
    <div className="return-button">
      <button
        onClick={() => {
          pageNavigate();
          windowScroll();
        }}
        style={{ display: hideButton ? "none" : "flex" }}
      >
        <img src={ReturnArrow} alt="icon return button" />
        Back
      </button>
    </div>
  );
}

export default ReturnButton;
