import { ContactData } from "../Data";
import TabsContact from "./TabsContact";

function Contact() {
  return (
    <div className="contact-container">
      <TabsContact data={ContactData}  />
    </div>
  );
}

export default Contact;
