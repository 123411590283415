import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { Key } from "react";
import Button from "./Button";
import { HomeButton } from "../Data";

function TabsHome(props: any) {
  const { data } = props;

  return (
    <Tabs
      isFitted
      align="center"
      variant="line"
      mt="10rem"
      colorScheme="blackAlpha"
    >
      <TabList>
        {data.map(
          (
            tab: {
              name: string;
            },
            index: Key | null
          ) => (
            <Tab
              key={index}
              className="tabs"
              sx={{
                fontFamily: "Rajdhani",
                fontSize: "1.3rem",
                fontWeight: "600",
              }}
            >
              {tab.name}
            </Tab>
          )
        )}
      </TabList>
      <TabPanels w={"100vw"}>
        {data.map(
          (
            tab: {
              image: string | undefined;
              alt: string | undefined;
              text: string | undefined;
            },
            index: Key | null | undefined
          ) => (
            <TabPanel key={index} className="TabPanel">
              <div className="tabs-images-container">
                <div className="tabs-content tabs-text">
                  <p>{tab.text}</p>
                  <div className="tabs-home-button">
                    <Button data={HomeButton} />
                  </div>
                </div>
                <div className="tabs-content tabs-image">
                  <img src={tab.image} alt={tab.alt} />
                </div>
              </div>
            </TabPanel>
          )
        )}
      </TabPanels>
    </Tabs>
  );
}

export default TabsHome;
