import React from 'react'
import PageUnderConstruction from '../PageUnderConstruction'

function VideoTutorial() {
  return (
    <div>
        <PageUnderConstruction />
    </div>
  )
}

export default VideoTutorial