import { NavLink } from "react-router-dom";
import FacebookIcon from "../Assets/Icon - Facebook.svg";

function Facebook() {
  return (
    <div className="social-media-icon">
      <NavLink to={"/"}>
        <img src={FacebookIcon} alt="" />
      </NavLink>
    </div>
  );
}

export default Facebook;
