import UnderConstruction from "../Assets/UnderConstruction.png";

function PageUnderConstruction() {
  return (
    <div className="page-under-construction">
      <div className="page-under-construction-image">
        <img src={UnderConstruction} alt="Page under construction" />
      </div>
    </div>
  );
}

export default PageUnderConstruction;
