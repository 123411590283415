import MenuOpen from "../Assets/Icon - OpenMenu.svg";

function OpenMobileMenu() {
  return (
    <div className="menu-toggle-button">
      <img src={MenuOpen} alt="" />
    </div>
  );
}

export default OpenMobileMenu;
