import CloseIcon from "../Assets/Icon - CloseMenu.svg";

function CloseMobileMenu() {
  return (
    <div className="menu-toggle-button">
      <img src={CloseIcon} alt="" />
    </div>
  );
}

export default CloseMobileMenu;
