import { TabsContent } from "../Data";
import TabsHome from "./TabsHome";

function Home() {
  return (
    <div>
      <div>
        <TabsHome data={TabsContent} />
      </div>
    </div>
  );
}

export default Home;
