import { NavLink } from "react-router-dom";
import "../../Styles/styles.sass";

function NavbarProps(props: { id: any; link: string; name: string }) {
  const { id, link, name } = props;
  return (
    <nav>
      <ul className="menu-list navbar-list">
        <li id={id} className="current">
          <NavLink
            to={link}
            data-hover={name}
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            {name}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default NavbarProps;
