import PageUnderConstruction from './PageUnderConstruction'

function AboutUs() {
  return (
    <div>
        <PageUnderConstruction />
    </div>
  )
}

export default AboutUs